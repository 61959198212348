#message-box {
    .title {
        font-size: 20px;
        font-weight: bold;
        display: block;

        &:after {
            margin-top: 15px;
            content: '';
            display: block;
            height: 1px;
            width: 100%;
            background-color: $text-highlight;
        }
    }

    .subtitle {
        font-size: 16px;
    }
    .message {
        font-size: 20px;
    }
}
#logo-box {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    max-width: 960px;
}

#countdown-box {
    //background-color: black;
    .countdown-title {
        font-size: 20px;
        font-weight: 600;
    }
    .countdown-outro {
        font-size: 16px;
    }
}
#logo-box + #countdown-box {
    margin-top: 50px;
}

@media (min-width: 360px) {
    #countdown-box {
        .countdown-title {
            font-size: 24px;
        }
        .countdown-outro {
            font-size: 20px;
        }
    }
}

@media (min-width: 500px) {
}

@media (min-width: 768px) {
    #countdown-box {
        .countdown-title {
            font-size: 30px;
        }
        .countdown-outro {
            font-size: 22px;
        }
    }

    #logo-box {
    }

    #message-box {
        .title {
            font-size: 34px;

            &:after {
                margin-top: 15px;
            }
        }

        .subtitle {
            font-size: 20px;
        }
        .message {
            font-size: 22px;
        }
    }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
    #countdown-box {
        .countdown-title {
            font-size: 36px;
        }

        .countdown-outro {
            font-size: 24px;
        }
    }

    #logo-box {
    }

    #message-box {
        .title {
            font-size: 44px;

            &:after {
                margin: 25px 0;
            }
        }

        .subtitle {
            font-size: 24px;
        }
        .message {
            font-size: 24px;
        }
    }
}

@media (min-width: 1400px) {
}

@media (min-width: 1600px) {
}
