#countdown-box {
    .countdown-title {
        font-size: 26px;
        color: $text-highlight;
        text-align: center;
        font-style: italic;
    }
    .countdown-outro {
        font-size: 14px;
        text-align: center;
        max-width: 460px;
        margin: 25px auto 0 auto;
    }
}

#time2go {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-color;
    padding-top: 5px;
    //	padding: 15px;

    .t2-counter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        //width: 25%;

        //padding: 0 12px;
        //max-width: 160px;

        .short {
            display: none;
        }

        .long {
            font-size: 14px;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        &:last-child {
            margin-right: 0;

            .t2-digit:after {
                content: '';
                padding: 0;
            }
            .t2-digit:before {
                width: 90%;
            }
        }
        // &:first-child {

        //     .t2-digit:after {
        //         content: '';
        //     }
        // }
    }
    .t2-part {
        line-height: 1;
        //background-color: $text-color;
        //color: $text-reverse;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .t2-digit {
        font-size: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1em;
        //width: 100%;
        padding-top: 5px;
        padding-bottom: 3px;
        position: relative;
        border-top: 1px solid $text-color;

        &:after {
            content: ':';
            padding: 0 10px;
            // position: absolute;
            // right: -5px;
        }
        &:before {
            content: ' ';
            position: absolute;
            height: 1px;
            width: 60%;
            background-color: #fff;
            bottom: 0px;
            left: 0;
        }
    }
}

@media (min-width: 768px) {
    #countdown-box {
        .countdown-title {
            font-size: 30px;
        }
        .countdown-outro {
            font-size: 18px;
        }
    }
    #time2go {
        //padding: 50px 10vw;

        .t2-counter {
            //padding: 0 25px;

            .long {
                font-size: 16px;
            }
        }

        .t2-digit {
            font-size: 70px;
            //width: 120px;

            &:before {
                width: 70%;
            }
        }
    }
}

@media (min-width: 992px) {
    #time2go {
        //padding: 25px 0;

        .t2-counter {
            //padding: 0 35px;
            //width: 100%;

            .long {
                font-size: 18px;
            }
        }

        .t2-digit {
            font-size: 100px;
            //width: 180px;

            &:after {
                //right: -20px;
            }
        }
    }
}

@media (min-width: 1200px) {
    #countdown-box {
        .countdown-title {
            font-size: 40px;
        }
    }

    #time2go {
        .t2-counter {
            //  width: 190px;
        }
    }
}
