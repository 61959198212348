//@import 'partials-online/fonts';
@import 'partials-online/variables';

$enable-gradients: true;
$link-color: $link-color !default;
$link-decoration: none !default;
$link-hover-color: $text-highlight !default;
$link-hover-decoration: none !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 35% !default;
$border-radius: 0;

$theme-colors: (
    'default': #fff,
    'event': $text-highlight,
);

// Resolved from webpack
@import 'bootstrap';

@import 'partials-online/common';
@import 'partials-online/helpers';
@import 'partials-online/layout';
@import 'partials-online/footer';
@import 'partials-online/components';
@import 'partials-online/countdown';
//@import 'partials-online/navbar';
@import 'partials-online/live';
@import 'partials-online/forms';
@import 'partials-online/buttons';
//@import 'partials-online/agenda';
@import 'partials-online/survey';
//@import 'partials-online/thankyou';
@import 'partials-online/landing';
@import 'partials-online/access';
@import 'partials-online/password-meter';

// @import 'partials-online/ie';
// @import 'partials-online/scenes';
