// .helpcrunch-iframe-wrapper {
//     iframe {
//         right: 10px!important;
//         left: auto!important;
//         top: 60px!important;
//     }
// }

.helpcrunch-iframe-wrapper {
    iframe {
        //right: 10px!important;
        //left: auto!important;
        top: 10px;
        //bottom: 15px!important;
    }
}
.bg {
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.6) 40%);
}

#av-live {
    margin-top: 25px;
    width: 100%;
    padding: 0 15px;
    background-color: $av-live-background;
    .vjs-big-play-button {
        display: none;
    }
}

#av-content {
    position: relative;
    background-color: $av-content-background;
    padding: 0;

    .av-live-frame-border {
        border: 5px solid #fff;
        // background-color: $av-live-frame-border;
        // padding: 15px;
    }
}

#room-teleport {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;

    .teleport {
        display: block;
        width: 150px;
        margin: auto;
    }
}

#teleport-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: black;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        padding: 0 25px;
        text-align: center;
    }
}

#av-aside {
    position: relative;
    max-width: 550px;
    padding: 0 0 0px 0;
    margin-top: 75px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;

    .inner {
        position: relative;
        border: 2px solid $text-highlight;
        background-color: $av-aside-background;

        &.d-none {
            height: 0;
        }
    }
}

#btn-toggle-room-status {
    font-weight: bold;
    font-size: 22px;
    text-shadow: none;
    box-shadow: none;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 75%;
    border: 2px solid $text-highlight;
    top: -35px;
    left: calc(50% - (50px / 2));
    padding: 0;
    z-index: 10;
}

#room-box {
    margin: 0rem auto;
    padding: 5px;
    background-color: $room-box-background;
    position: relative;
}
#room-box-sponsor {
    padding: 0 10px;
    max-width: 500px;
    margin: 0 auto;
}

#room-box-title {
    .title {
        background-color: #000;
        color: #fff;
        padding: 5px 15px;
        font-weight: bold;
        font-size: 16px;
    }
}

#video-box,
#iframe-box {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;

    #live {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

#player-iframe {
    iframe {
        position: absolute;
        left: 0;
        top: 0;
    }
}

#av-live[data-av-theme='live-aside'] {
    #room-box {
    }
}

@media (min-width: 768px) {
    // #room-box-title {
    //     top: 20px;

    //     .title {
    //         font-size: 30px;
    //     }
    // }

    .helpcrunch-iframe-wrapper {
        iframe {
            right: 10px !important;
            left: auto !important;
            //top: 270px;
            //bottom: 15px!important;
        }
    }
    #btn-toggle-room-status {
        font-weight: bold;
        font-size: 22px;
        text-shadow: none;
        box-shadow: none;
        position: absolute;
        width: 75px;
        height: 75px;
        border-radius: 75%;
        border: 2px solid $text-highlight;
        top: -55px;
        left: calc(50% - (75px / 2));
        padding: 0;
        z-index: 10;
    }
}
@media (min-width: 992px) {
    #av-live[data-av-theme='live-full-chat'] {
        // spazio verticale per area video
        // header 83px + footer 130 px + padding soprasotto (15*2) 30px = 243px

        //width: min(100vw - 30px, (100vh - 83px - 130px - 30px )* 16/9 );
        //width: min(100vw - 30px, (100vh - 530px - 60px )* 16/9 );
        width: 100%;
        //padding: 2vw;
        max-width: 1200px;
        //width: 100vh;
        // width: min(100vw - 30px, (100vh - 530px )* 16/9 );
        margin-left: auto;
        margin-right: auto;

        .video-js .vjs-fullscreen-control {
            margin-right: min(400px, 50%);
        }

        #room-box {
            margin: 0;
            width: min(400px, 50%);
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 10;
        }
    }

    #av-live[data-av-theme='live-aside'] {
        justify-content: center;

        width: 100%;
        //padding: 2vw;
        //max-width: 1600px;
        width: min(100vw - 30px, (100vh - (100vw / 15) - (100vh / 75 * 8) - 50px) * 16/9 + 300px);

        margin: 0px auto 0 auto;

        #av-aside {
            //max-width: 500px;
            padding-left: 25px;
            margin: 0;
        }
        #room-box {
            margin: 0;
        }

        &.chat-closed #av-aside {
            max-width: 150px;
        }
    }

    #room-teleport {
        margin-top: 5px;
        margin-bottom: 5px;
        .teleport {
            width: 100px;
        }
    }

    #btn-toggle-room-status {
        top: auto;
        left: auto;
        right: -25px;
        bottom: -10px;
    }

    .helpcrunch-iframe-wrapper {
        iframe {
            right: 10px !important;
            left: auto !important;
            //top: 10vw;
            //bottom: 15px!important;
        }
    }

    #av-aside {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
    #room-teleport {
        margin-top: 25px;
        margin-bottom: 25px;

        .teleport {
            width: 150px;
        }
    }
}

@media (min-width: 1600px) {
    // #av-live[data-av-theme="live-full-chat"] {
    //     width: min(100vw - 30px, (100vh - 571px )* 16/9 );
    // }

    // #av-live[data-av-theme="live-aside"] {
    //     width: min(100vw - 30px, (100vh - 571px)* 16/9 + 300px );

    // }
}

@media (min-height: 800px) {
    .helpcrunch-iframe-wrapper {
        iframe {
            top: 10vw;
        }
    }
}
