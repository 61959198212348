.survey {
    .select2-dropdown {
        background-color: transparent;
        border-color: $text-highlight;
        color: $text-color;
        text-align: center;
        font-weight: 600;
    }

    .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .select2-results__option:nth-child(odd) {
        background-color: rgba(255, 255, 255, 0.2);
    }

    .select2-container--default .select2-results > .select2-results__options {
        max-height: 252px;
    }
    .select2-container--open .select2-dropdown--below {
    }

    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
        border-color: $text-color transparent transparent transparent;
        border-width: 13px 10px 0 10px;
        left: -5px;
        top: 14px;
    }
}

#poll-box {
    width: 100%;

    .poll {
        text-align: center;

        .divider {
            text-align: center;
        }

        .rating.color-default .star-container .star i {
            color: $text-highlight;
        }

        .star {
            cursor: pointer;
            width: 50px;
        }

        .rating {
            margin-bottom: 2rem;
        }
    }
    .poll-title,
    .poll-body,
    .poll-footer {
    }

    .poll-title {
        position: relative;
        display: inline-block;
        margin-bottom: 35px;
        font-weight: 600;
        font-size: 27px;

        &.underlined:after {
            content: '';
            width: 100%;
            height: 2px;
            background-color: $text-highlight;
            position: absolute;
            bottom: -20px;
            left: 0;
        }
    }

    .poll-description {
        font-size: 24px;
        font-weight: 600;
        padding: 2rem 0 0 0;
    }
    .poll-message {
        font-size: 18px;
    }

    .poll-body.poll-body-small {
        max-width: 350px;
        margin: 0 auto;
    }

    .poll-text-value {
        padding: 15px;
        background-color: $dark-background;
        color: $text-color;
    }

    .poll-footer {
        display: flex;
        justify-content: center;
        align-items: center;

        .poll-page {
            font-size: 25px;
        }

        .poll-btn {
            color: $text-color;
            background: $text-highlight;
            padding: 0 15px;

            &:hover {
                color: #1c1a1d;
            }
            &:disabled {
                color: $text-disabled;
            }

            i {
                font-size: 36px;
            }
        }

        .prev-btn,
        .next-btn {
            img {
                max-width: 25px;
            }
            svg {
                width: 25px;
                height: auto;
            }
        }
    }

    .poll-msg {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .poll-selector {
        margin-bottom: 290px;
        max-width: 420px;
        margin-left: auto;
        margin-right: auto;
    }

    .select2-container--default .select2-selection--single {
        background-color: transparent;
        border: 2px solid $text-highlight;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered,
    .select2-selection__placeholder {
        color: #fff;
        font-weight: 600;
    }

    .select2-container .select2-selection--single {
        height: 42px;
    }
}

@media (min-width: 632px) {
}

@media (min-width: 768px) {
    #poll-box {
        .poll-title {
            font-size: 34px;
        }

        .poll-description {
            font-size: 23px;
            padding-top: 1rem;
        }
        .poll-intro .poll-description {
            padding-top: 20vh;
        }

        .poll-body.poll-body-small {
            max-width: 450px;
        }
    }
}

@media (min-width: 992px) {
    .survey {
        .select2-dropdown {
            font-size: 18px;
        }
    }
    #poll-box {
        .poll-title {
            font-size: 38px;
        }

        .poll-description {
            font-size: 29px;
        }

        .poll-body.poll-body-small {
            max-width: 700px;
        }

        .poll-msg {
            font-size: 27px;
        }
        .poll-selector {
            max-width: 550px;
            font-size: 18px;
        }
        .poll-footer {
            .poll-page {
                font-size: 32px;
            }

            .poll-btn {
                i {
                    font-size: 55px;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    #poll-box {
        max-width: 1280px;
        margin: 0 auto;

        .poll-msg {
            font-size: 36px;
        }
    }
}
