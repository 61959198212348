.page-bck {
    background: url('/assets/img/bg-m.jpg') $background-color center;
}

img.landscape {
    display: none;
}

.header-image {
    position: relative;

    .help {
        position: absolute;
        right: 25px;
        bottom: -40px;
        font-weight: bold;
        font-size: 16px;
    }
}
#view {
    min-height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
}

#view.view-vertical {
    display: flex;
    flex-direction: column;
}
#view.view-horizontal {
    height: 100%;
}

#main {
    padding: 1rem 0.5rem;
    flex-grow: 1;

    &.main-content-h {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
    &.main-content-v {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    &.main-content-vc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
img.portrait {
    display: block;
}
img.tablet {
    display: none;
}
img.landscape {
    display: none;
}

@media (min-width: 768px) {
    .page-bck {
        background: url('/assets/img/bg.jpg') $background-color center;
    }

    img.portrait {
        display: none;
    }
    img.tablet {
        display: block;
    }
    img.landscape {
        display: none;
    }
}
@media (min-width: 992px) {
    img.portrait {
        display: none;
    }
    img.tablet {
        display: none;
    }
    img.landscape {
        display: block;
    }

    .header-image {
        .help {
            position: absolute;
            right: 25px;
            bottom: auto;
            top: 110%;
        }
    }

    #main {
        padding-left: 2vw;
        padding-right: 2vw;

        &.main-content-h {
            flex-direction: row;
            align-items: center;
        }

        &.main-content-v {
            padding: 25px 0;
            //padding-bottom:
            //min-height: min(500px, calc(100vh - (100vw / 15) - (100vh / 75 * 8) - 50px));
        }
    }
}

@media (min-width: 1400px) {
    .header-image {
        .help {
            position: absolute;
            right: 6vw;
            bottom: auto;
            top: 110%;
        }
    }
}
